import React, { useEffect } from 'react';
import { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import useFetchData from '../../../hooks/useFetchData';


const MonthwiseProfitGraph = () => {
  const {items:profit}=useFetchData("https://servers.thakurgaonexpress.com/mosquesoftware/monthwiseprofit")

  const maxSalesValue = Math.max(...profit.map(item => item.profit));

  return (
    <div className="mt-16">
      <h1 className='text-lg font-bold'>Month Wise Profit (This Year)</h1>
      <ResponsiveContainer width="100%" height={300}>
      <BarChart width={800} height={300} data={profit} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis domain={[0, maxSalesValue]}/>
        <Tooltip />
        <Legend />
        <Bar dataKey="profit" fill="#00A36C" />
        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
      </BarChart>
      </ResponsiveContainer>

      {/* <ResponsiveContainer width="100%" height={300}>
        <LineChart data={sales} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis domain={[0, maxSalesValue]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer> */}
    </div>
  );
};

export default MonthwiseProfitGraph;
