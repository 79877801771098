import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToPurchaseInvoice } from '../../../../features/PurchaseSlice/PurchaseSlice';
import Loader from '../../../Shared/Loader';
import UpdateModal from './UpdateModal';



const AddPurchaseProduct = ({ setAudioData }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [updateProduct, setUpdateProduct] = useState(null);
    const [searchText, setSearchText]=useState();

    const dispatch= useDispatch();


    useEffect(() => {
        fetch(`https://servers.thakurgaonexpress.com/mosquesoftware/getproducts`)
            .then(res => res.json())
            .then(data => {
                setProducts(data);
                setLoading(false)
            })
    }, [])

    if (loading) {
        return <Loader />
    }

   

    const handleSearchResult = (event) => {
        setSearchText(event.target.value)
        const match = products.filter(product => product.name.includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }


    const handleAddProductToInvoice=(product)=>{
        dispatch(addToPurchaseInvoice(product))
        setUpdateProduct(product)
        setIsSearching(false)
        setSearchText('');
        
    }


    // const handleAddProduct = (product) => {

    //     // fetch(`https://servers.thakurgaonexpress.com/mosquesoftware/getsuppliers/${Supplier._id}`)
    //     //     .then(res => res.json())
    //     //     .then(data => {
    //     //         dispatch(editSupplier(data))
    //     //     })
    //     setUpdateProduct(product);
    // }


    return (
        <div className='w-10/12 lg:w-11/12 mx-auto relative  z-50'>
            <h1 className='text-xl border-b-4 border-dark-purple font-bold mt-8 inline-block'>Select Product</h1><br />
            <div className='flex justify-left items-center my-4'>
                <div class="form-control w-80">
                    <input  onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Product" class="input input-bordered border-dark-purple w-full max-w-xs" />
                </div>
            </div>

            <hr />
            <div style={ isSearching ? {} : {visibility:'hidden'}} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching?'h-60':"h-8"} w-80 overflow-auto`}>
                {
                    isSearching ? searchResult.map(product => 
                        <label for="update-modal" onClick={() => handleAddProductToInvoice(product)}  style={{width:'300px', height:'50px'}} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>) :
                        products.map(product => 
                            <label for="update-modal" onClick={() => handleAddProductToInvoice(product)}  style={{width:'300px', height:'50px'}} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>)
                }
            </div>
            <div className='w-8/12'>
            {
                updateProduct && <UpdateModal setUpdateProduct={setUpdateProduct} updateProduct={updateProduct}></UpdateModal>
            }
            </div>
        </div >
    );
};

export default AddPurchaseProduct;