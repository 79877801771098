import { createBrowserRouter } from "react-router-dom";
import Main from "../layout/Main";
import Login from "../pages/Auth/Login";
import PrivateRoute from "../pages/Auth/RequireAuth";
import AddCustomer from "../pages/Dashboard/AddCustomer/AddCustomer";
import BookingList from "../pages/Dashboard/BookingList/BookingList";
import CompletedList from "../pages/Dashboard/CompletedList/BookingList";
import Dashboard from "../pages/Dashboard/Dashboard";
import DashboardHome from "../pages/Dashboard/DashboardHome";
import DeletedList from "../pages/Dashboard/DeletedList/BookingList";
import Home from "../pages/ServiceBooking/Home";
import ServiceField from "../pages/ServiceBooking/ServiceField";
import SubmitBooking from "../pages/ServiceBooking/SubmitBooking";
import AddBrand from "../pages/ProductInfo/AddBrand/AddBrand";
import AddCategory from "../pages/ProductInfo/AddCategory/AddCategory";
import AddOrigin from "../pages/ProductInfo/AddOrigin/AddOrigin";
import AddProduct from "../pages/ProductInfo/AddProduct/AddSupplier";
import AddSupplier from "../pages/ProductInfo/AddSupplier/AddSupplier";
import AddUnit from "../pages/ProductInfo/AddUnit/AddUnit";
import AddPurchase from "../pages/Dashboard/Purchase/AddPurchase/AddPurchase";
import PurchaseList from "../pages/Dashboard/Purchase/ManagePurchase/PurchaseList";
import UpdateInvoice from "../pages/Dashboard/Purchase/ManagePurchase/UpdateInvoice/UpdateInvoice";
import AddSales from "../pages/SalesInvoice/AddSales";
import SalesList from "../pages/SalesInvoice/ManageInvoice/SalesList";
import CurrentStock from "../pages/Dashboard/Reports/CurrentStock/CurrentStock";
import InvoiceWiseProfit from "../pages/Dashboard/Reports/InvoiceWiseProfit/InvoiceWiseProfit";
import UpdateSalesInvoice from "../pages/SalesInvoice/ManageInvoice/UpdateInvoice/UpdateSalesInvoice";
import AddBikeSales from "../pages/Dashboard/Sales/AddSales/AddBikeSales";
import BikeSalesList from "../pages/Dashboard/Sales/ViewSales/BikeSalesList";
import UpdateBikeSalesInvoice from "../pages/Dashboard/Sales/ManageSales/UpdateBikeSalesInvoice";
import AddMainCustomer from "../pages/Dashboard/Customers/AddMainCustomer";
import AddExpenseHead from "../pages/Dashboard/AccountsInfo/AddExpenseHead/AddExpenseHead";
import AddIncomeHead from "../pages/Dashboard/AccountsInfo/AddIncomeHead/AddIncomeHead";
import AddBankAccount from "../pages/Dashboard/AccountsInfo/AddBankAccounts/AddBankAccount";
import AddLoanAccount from "../pages/Dashboard/AccountsInfo/AddLoanAccount/AddLoanAccount";
import AddIncome from "../pages/Dashboard/Transactions/AddIncome/AddIncome";
import AddExpense from "../pages/Dashboard/Transactions/AddExpense/AddExpense";
import AddCollection from "../pages/Dashboard/Transactions/AddCollection/AddCollection";
import AddPayment from "../pages/Dashboard/Transactions/AddPayment/AddPayment";
import AddBankDeposit from "../pages/Dashboard/Transactions/AddBankDeposit/AddBankDeposit";
import AddBankWithdraw from "../pages/Dashboard/Transactions/AddBankWithdraw/AddBankWithdraw";
import AddLoanTaken from "../pages/Dashboard/Transactions/AddLoanTaken/AddLoanTaken";
import AddLoanReturn from "../pages/Dashboard/Transactions/AddLoanReturn/AddLoanReturn";
import CashStatement from "../pages/Dashboard/Reports/CashStatement/CashStatement";
import BankStatement from "../pages/Dashboard/Reports/BankStatement/BankStatement";
import BalanceSheet from "../pages/Dashboard/Reports/BalanceSheet/BalanceSheet";
import AddOpeningCapital from "../pages/Dashboard/AccountsInfo/AddOpeningCapital/AddOpeningCapital";
import ProfitStatement from "../pages/Dashboard/Reports/ProfitStatement/ProfitStatement";
import CustomerDueReport from "../pages/Dashboard/Reports/CustomerDueReport/CustomerDueReport";
import DonorDueReportWithSMS from "../pages/Dashboard/Reports/CustomerDueReport/DonorDueReportWithSMS";
import SupplierDueReport from "../pages/Dashboard/Reports/SupplierDueReport/SupplierDueReport";
import AddUser from "../pages/Dashboard/UserManagement/AddUser/AddUser";
import LoanDueReport from "../pages/Dashboard/Reports/LoanDueReport/LoanDueReport";
import StockLedger from "../pages/Dashboard/Reports/StockLedger/StockLedger";
import CustomerLedgerReport from "../pages/Dashboard/Reports/CustomerLedgerReport/CustomerLedgerReport";
import SupplierLedgerReport from "../pages/Dashboard/Reports/SupplierLedgerReport/SupplierLedgerReport";
import ManageUser from "../pages/Dashboard/UserManagement/ManageUser/ManageUser";
import LoanLedgerReport from "../pages/Dashboard/Reports/LoanLedgerReport/LoanLedgerReport";
import TopSellingLessSelling from "../pages/Dashboard/Reports/TopSellingLessSelling/TopSellingLessSelling";
import StockOutReport from "../pages/Dashboard/Reports/StockOutReport/StockOutReport";
import AddPurchaseOrder from "../pages/Dashboard/Purchase/AddPurchaseOrder/AddPurchaseOrder";
import PurchaseOrderList from "../pages/Dashboard/Purchase/ManagePurchaseOrder/PurchaseOrderList";
import UpdatePurchaseOrder from "../pages/Dashboard/Purchase/ManagePurchaseOrder/UpdateInvoice/UpdatePurchaseOrder";
import BarCodeGen from "../pages/Dashboard/BarCodeGen/BarCodeGen";
import AddPosSales from "../pages/Dashboard/Sales/AddPosSales/AddPosSales";
import AddPosPurchase from "../pages/Dashboard/Purchase/AddPosPurchase/AddPosPurchase";
import PrintBarCode from "../pages/Dashboard/PrintBarCode/PrintBarCode";
import UpdatePosSales from "../pages/Dashboard/Sales/ManagePosSales/UpdatePosSales";
import UpdatePosPurchase from "../pages/Dashboard/Purchase/ManagePosPurchase/UpdatePosPurchase";
import ProfitStatementAdvance from "../pages/Dashboard/Reports/ProfitStatementAdvance/ProfitStatementAdvance";
import NewDashboard from "../pages/Dashboard/NewDashboard";
import MessageSetting from "../pages/Dashboard/Settings/MessageSetting";
import AddTarabiCollection from "../pages/Dashboard/Transactions/AddTarabiCollection/AddTarabiCollection";
import TarabiDueReportWithSMS from "../pages/Dashboard/Reports/CustomerDueReport/TarabiDueReportWithSMS";
import SendMessage from "../pages/Dashboard/Settings/SendMessage";




const routes = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        children: [
            {
                path: '/',
                element: <PrivateRoute><Dashboard />
                </PrivateRoute>,
                children: [
                    {
                        path: '/',
                        element: <DashboardHome />
                    },
                    // {
                    //     path:'/newdashboard',
                    //     element:<NewDashboard/>
                    // },
                    {
                        path: '/adduser',
                        element: <AddUser />
                    },
                    {
                        path: "addbrand",
                        element: <AddBrand />,
                    },
                    {
                        path: "addcategory",
                        element: <AddCategory />,
                    },
                    {
                        path: "addsupplier",
                        element: <AddSupplier />,
                    },
                    {
                        path: "addunit",
                        element: <AddUnit />,
                    },
                    {
                        path: "addorigin",
                        element: <AddOrigin />,
                    },
                    {
                        path: "addproduct",
                        element: <AddProduct />,
                    },
                    {
                        path: "addpurchase",
                        element: <AddPurchase />,
                    },
                    {
                        path: "addpospurchase",
                        element: <AddPosPurchase />,
                    },
                    {
                        path: "managepurchase",
                        element: <PurchaseList />,
                    },
                    {
                        path: "addpurchaseorder",
                        element: <AddPurchaseOrder />,
                    },
                    {
                        path: "managepurchaseorder",
                        element: <PurchaseOrderList />,
                    },
                    {
                        path: "managepurchaseorder/edit/:editId",
                        element: <UpdatePurchaseOrder />,
                    },
                    {
                        path: "managepurchase/edit/:editId",
                        element: <UpdateInvoice />,
                    },
                    {
                        path: "managepospurchase/edit/:editId",
                        element: <UpdatePosPurchase />,
                    },
                    {
                        path: "managesales/edit/:editId",
                        element: <UpdateSalesInvoice />,
                    },
                    {
                        path: "managedefaultsales/edit/:editId",
                        element: <UpdateBikeSalesInvoice />,
                    },
                    {
                        path: "addsales/:customerId",
                        element: <AddSales />,
                    },
                    {
                        path: "addsales",
                        element: <AddSales />,
                    },
                    {
                        path: "addpossales",
                        element: <AddPosSales />,
                    },
                    {
                        path: "updatepossales/edit/:editId",
                        element: <UpdatePosSales />,
                    },
                    {
                        path: "addmaincustomer",
                        element: <AddMainCustomer />,
                    },
                    {
                        path: "adddefaultsales",
                        element: <AddBikeSales />,
                    },
                    {
                        path: "managedefaultsales",
                        element: <BikeSalesList />,
                    },
                    {
                        path: "saleslist",
                        element: <SalesList />,
                    },

                    {
                        path: 'bookinglist',
                        element: <BookingList />
                    },
                    {
                        path: 'completedlist',
                        element: <CompletedList />
                    },
                    {
                        path: 'cancelledlist',
                        element: <DeletedList />
                    },
                    // Accounts Info
                    {
                        path: 'addexpensehead',
                        element: <AddExpenseHead />
                    },
                    {
                        path: 'addincomehead',
                        element: <AddIncomeHead />
                    },
                    {
                        path: 'addbankaccount',
                        element: <AddBankAccount />
                    },
                    {
                        path: 'addloanaccount',
                        element: <AddLoanAccount />
                    },
                    {
                        path: 'addopeningcapital',
                        element: <AddOpeningCapital />
                    },

                    // Transactions
                    {
                        path: 'addincome',
                        element: <AddIncome />
                    },
                    {
                        path: 'addexpense',
                        element: <AddExpense />
                    },
                    {
                        path: 'addcollection',
                        element: <AddCollection />
                    },
                    {
                        path: 'addtarabicollection',
                        element: <AddTarabiCollection />
                    },
                    {
                        path: 'addpayment',
                        element: <AddPayment />
                    },
                    {
                        path: 'addbankdeposit',
                        element: <AddBankDeposit />
                    },
                    {
                        path: 'addbankwithdraw',
                        element: <AddBankWithdraw />
                    },
                    {
                        path: 'addloantaken',
                        element: <AddLoanTaken />
                    },
                    {
                        path: 'addloanreturn',
                        element: <AddLoanReturn />
                    },
                    {
                        path: 'printbarcode',
                        element: <PrintBarCode />
                    },
                    {
                        path: 'smssetting',
                        element: <MessageSetting />
                    },
                    {
                        path: 'customsms',
                        element: <SendMessage />
                    },

                    // Reports
                    {
                        path: 'reports/cashstatement',
                        element: <CashStatement />
                    },
                    {
                        path: 'reports/bankstatement',
                        element: <BankStatement />
                    },
                    {
                        path: 'reports/balancesheet',
                        element: <BalanceSheet />
                    },
                    {
                        path: 'reports/profitsheet',
                        element: <ProfitStatement />
                    },
                    {
                        path: 'reports/profitsheetadvance',
                        element: <ProfitStatementAdvance />
                    },
                    {
                        path: 'reports/currentstock',
                        element: <CurrentStock />
                    },
                    {
                        path: 'reports/customerduereport',
                        element: <DonorDueReportWithSMS />
                    },
                    {
                        path: 'reports/tarabiduereport',
                        element: <TarabiDueReportWithSMS />
                    },
                    {
                        path: 'reports/supplierduereport',
                        element: <SupplierDueReport />
                    },
                    {
                        path: 'reports/loanduereport',
                        element: <LoanDueReport />
                    },
                    {
                        path: 'reports/invoiceprofit',
                        element: <InvoiceWiseProfit />
                    },
                    {
                        path: 'reports/stockledger',
                        element: <StockLedger />
                    },
                    {
                        path: 'reports/customerledger',
                        element: <CustomerLedgerReport />
                    },
                    {
                        path: 'reports/supplierledger',
                        element: <SupplierLedgerReport />
                    },
                    {
                        path: 'reports/laonledger',
                        element: <LoanLedgerReport />
                    },
                    {
                        path: 'reports/topsellinglessselling',
                        element: <TopSellingLessSelling />
                    },
                    {
                        path: 'reports/stockoutreport',
                        element: <StockOutReport />
                    },
                    {
                        path: 'reports/barcode',
                        element: <BarCodeGen />
                    },
                ]
            },
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/addcustomer',
                element: <AddCustomer />
            },
            {
                path: 'times',
                element: <ServiceField />,
            },
            {
                path: 'times/:serviceId',
                element: <SubmitBooking />
            }

        ]
    },
    // {
    //     path:'/:serviceId',
    // }
])


export default routes;