import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const useCurrentStock = () => {
    const [sales, setSales] = useState([]);
    const [purchases, setPurchases] = useState([]);

    useEffect(() => {
        const url = `https://servers.thakurgaonexpress.com/mosquesoftware/getsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSales(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://servers.thakurgaonexpress.com/mosquesoftware/getpurchaseproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setPurchases(data);
            })
    }, []);

    const { data: products, isLoading } = useQuery(['products'], () => fetch('https://servers.thakurgaonexpress.com/mosquesoftware/getproducts').then(res => res.json()));



    // Stock Report
    // Sales Quantity

    let array = [];

    for (let i = 0; i < products?.length; i++) {
        const filtered = sales.filter(sale => sale?.code === products[i]?.code);
        array.push(filtered);
    }

    let array2 = [];
    for (let i = 0; i < array?.length; i++) {
        const salesQuantity = array[i]?.map(a => parseFloat(a.quantity));
        const totalSales = salesQuantity?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        array2.push(totalSales);
    }

    // Purchase Quantity

    let array3 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = purchases.filter(purchase => purchase.code === products[i].code);
        array3.push(filtered);
    }

    let array4 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array3[i]?.map(a => parseFloat(a.quantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array4.push(totalLoanReturn)
    }


    // Joining Balance To data

    let stockData = [];
    for (let i = 0; i < products?.length; i++) {
        const product = ({
            _id: products[i]._id, name: products[i]?.name,
            code: products[i].code, category: products[i].category,
            brand: products[i].brand, minimum: products[i].minimum,
            origin: products[i].origin, unit: products[i].unit, status: products[i].status,
            purchase: products[i].purchase, sell: products[i].sell, totalSale: array2[i],
            totalPurchase: array4[i], opening: products[i].opening,
            stock: (products[i]?.opening) + array4[i] - array2[i], stockCost: (products[i].opening
                + array4[i] - array2[i]) * products[i].purchase,
        });

        stockData.push(product);
    }

    const filtered = stockData?.filter((product) => product.stock > 0);


    // if (isLoading) {
    //     return <Loader />
    // }

    return { filtered, stockData, isLoading };
};

export default useCurrentStock;
