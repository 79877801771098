import logo from '../../assets/images/logo.jpeg'

const BusinessInfo = {
    name: "Fakir Bari Jame Mosque",
    fullName: "Fakir Bari Jame Mosque",
    dealer: "()",
    address: "Kawraid,Sreepur, Gazipur",
    mobile: "Mobile: 01971909352",
    img: logo,
}

export default BusinessInfo;
