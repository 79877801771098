import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editBrand } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';
import MessageHistory from './MessageHistory';



const MessageSetting = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [sms, setSms] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/mosquesoftware/getsms')
            .then(res => res.json())
            .then(data => {
                setSms(data);
                setLoading(false)
            })
    }, [sms, setSms])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0]
        const quantity = parseFloat(data.quantity);
        const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
        const number = '01772545974';
        const msg = `প্রিয় ClearSoftwares, আমি Fakirbari Jame Mosque আমার ${quantity} টি SMS প্রয়োজন`;
        const message = encodeURI(msg);

        const price = quantity * 0.3333;

        const percentageIncrease = 2; // Adjust this value based on your requirement
        const withCharge = price + (price * (percentageIncrease / 100));
        const customerNo = '01971909352'
        const msg2 = `প্রিয় গ্রাহক, For ${quantity} SMS ${number} এ ${(withCharge).toFixed(2)}/- Bkash Send Money করুন`;
        const message2 = encodeURI(msg2);

        const messageToSupplier = () => {
            const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${number}&message=${message}`
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })
        }
        const messageToCustomer = () => {
            const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${customerNo}&message=${message2}`
            fetch(url)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })


        }

        messageToSupplier();
        messageToCustomer();

    }


    return (
        <>
            <div className='shadow-md px-2 pb-5 my-5 text-xs'>
                <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Order SMS</h1>
                <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col lg:flex-row md:flex-row gap-2 justify-center items-center'>
                    <div class="form-control w-80 max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">SMS Quantity</span>
                        </label>
                        <input {...register("quantity")} type="number" placeholder="Type SMS Quantity Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <input type="submit" value='Order SMS' className='btn bg-red-600 text-white lg:mt-9' />
                </form>

                {
                    sms.length ? <div class="overflow-x-auto mt-8">
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>SL</th>
                                    <th className='bg-red-600 text-white normal-case'>Available SMS</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    sms.map((s, index) => <tr className='hover'>
                                        <td>{index + 1}</td>
                                        <td>{s.smsAmount}</td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div> : ""
                }

            </div>
            <h1 className='text-xl font-bold mt-10'>SMS History</h1>
            <MessageHistory />
        </>
    );
};

export default MessageSetting;